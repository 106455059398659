/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 49번째 로그에서는 새로운 녹음 장비, 분더리스트 재인수설, 당근마켓 400억 투자 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "새로운 녹음 장비: StudioLive AR 12, SM 57"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.shure.com/en-US/products/microphones/sm57"
  }, "SM57 - Dynamic Instrument Microphone")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.presonus.com/products/StudioLive-AR12-USB"
  }, "StudioLive AR12 USB | PreSonus")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.shure.com/en-US/products/microphones/sm58"
  }, "SM58® - Dynamic Vocal Microphone")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/channel/UCPwutg-Q08jMQoPClzD0lTQ/"
  }, "44bits - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bluedesigns.com/products/yeti/"
  }, "Blue - Yeti")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rogueamoeba.com/audiohijack/"
  }, "Rogue Amoeba | Audio Hijack: Record Any Audio on MacOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rogueamoeba.com/loopback/"
  }, "Rogue Amoeba | Loopback: Cable-Free Audio Routing")), "\n"), "\n", React.createElement(_components.h2, null, "분더리스트(Wunderlist) 재인수설"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wunderlist.com/"
  }, "Wunderlist | To-do list, Reminders, Errands - App of the Year!: Wunderlist")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2018/3/21/17146308/microsoft-wunderlist-to-do-app-acquisition-complicated"
  }, "Microsoft’s Wunderlist acquisition is getting complicated - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2019/9/8/20855201/wunderlist-buy-back-offer-microsoft-christian-reber"
  }, "Wunderlist founder offers to buy back app from Microsoft - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/christianreber/status/1169985802421592064?ref_src=twsrc%5Etfw"
  }, "Christian Reber on Twitter: \"Still sad @Microsoft wants to shut down @Wunderlist...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.engadget.com/2018/07/16/instapaper-buys-itself-back-pinterest/"
  }, "Instapaper buys itself back from Pinterest")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/jnunemaker/status/1002610279295930369"
  }, "John Nunemaker on Twitter: \"Myself, @orderedlist and @jqr have acquired @speakerdeck (https://t.co/Zfo2IagFaG) from GitHub. Really excited about our plans for it's future!\"")), "\n"), "\n", React.createElement(_components.h2, null, "당근마켓 400억 투자"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bloter.net/archives/352600"
  }, "‘당근마켓’, 400억원 투자 받았다 | Bloter.net")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/nacyo_t/status/1161557317680111616"
  }, "nacyot on Twitter: \"당근마켓, 안드로이드 인기순위 1위!!! 🎉🎉🎉 https://t.co/72A5onBRZn\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "-"
  }, "당근마켓과 함께 할 개발자를 찾고 있어요!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://store.naver.com/restaurants/detail?id=439017864"
  }, "셰프팔레트 : 네이버")), "\n"), "\n", React.createElement(_components.h2, null, "W3C TTS 워킹그룹 / 인명 독음 문제"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.w3.org/blog/news/archives/7934"
  }, "First Public Working Drafts: Pronunciation | W3C News")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/polly/latest/dg/ssml.html"
  }, "Generating Speech from SSML Documents - Amazon Polly")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hangulize.org/"
  }, "Hangulize")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/International_Phonetic_Alphabet"
  }, "International Phonetic Alphabet - Wikipedia")), "\n"), "\n", React.createElement(_components.h2, null, "HashiConf vs. Apple 이벤트"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/resources/hashiconf-2019-opening-keynote"
  }, "HashiConf 2019 Opening Keynote")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/apple-events/september-2019/"
  }, "Apple 이벤트 - 2019년 9월 키노트 - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/estima7/status/1171961901665775616"
  }, "에스티마 on Twitter: \"역대 아이폰 가격 추이. (닛케이) https://t.co/wVZLFOqM7H\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://9to5mac.com/guides/iphone-se-2/"
  }, "iPhone SE 2 - 9to5Mac")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
